<template>
  <div>
    <Filters />
    <h1 class="text-h4 my-10">
      Audience Annuelle Reworld Media
      <dag-info
        :dags="[
          'ga_get_and_store_site_daily_dag',
          'wyzistat_get_and_store_data_daily_dag',
        ]"
      />
    </h1>
    <RWMDataHeader />
    <MonthlyComparisonChart
      dataLabel="monthlyPageViews"
      title="Évolution des PVs"
    />
    <TopSitesChart dataLabel="topPageViews" title="Meilleurs site par PV" />
    <MonthlyComparisonChart
      dataLabel="monthlySessions"
      title="Évolution des Sessions"
    />
    <TopSitesChart
      dataLabel="topSessions"
      title="Meilleurs site par Sessions"
    />
  </div>
</template>

<script>
import { saveRmraView } from "@/utils/rmr-analytics";
import { mdiArrowUp } from "@mdi/js";

import DagInfo from "@/components/common/menus/DagInfo";
import RWMDataHeader from "@/components/general/rwm-data/cards/RWMDataHeader.vue";
import MonthlyComparisonChart from "@/components/general/rwm-data/charts/MonthlyComparisonChart.vue";
import TopSitesChart from "@/components/general/rwm-data/charts/TopSitesChart.vue";
import Filters from "@/components/general/rwm-data/filters/Filters";
export default {
  name: "RWMData",
  components: {
    DagInfo,
    Filters,
    RWMDataHeader,
    MonthlyComparisonChart,
    TopSitesChart,
  },
  async mounted() {
    saveRmraView(this.$route);
    await this.$store.dispatch("common/updateApplyFilters", true);
    await this.getData();
  },
  methods: {
    async getData() {
      if (this.applyFilters) {
        try {
          const params = {
            audience_type: this.audienceType,
            ...(this.sites.length > 0 ? { sites: this.sites.join(",") } : {}),
          };
          await this.$store.dispatch("general/getRwmData", params);
        } catch (error) {
          console.error(error);
        } finally {
          await this.$store.dispatch("common/updateApplyFilters", false);
        }
      }
    },
  },
  data() {
    return {
      mdiArrowUp,
    };
  },
  computed: {
    loading: {
      get() {
        return this.$store.getters["common/getLoading"];
      },
      set(val) {
        this.$store.dispatch("common/updateLoading", val);
      },
    },
    sites() {
      return this.$store.getters["general/getSites"];
    },
    audienceType() {
      return this.$store.getters["general/getAudienceType"];
    },
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
  },
  watch: {
    applyFilters() {
      this.getData();
    },
  },
};
</script>
