<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header> </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="3">
            <site-group-filter store="general" />
          </v-col>
          <v-col cols="3">
            <site-filter store="general" />
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="audienceType"
              :items="audienceTypes"
              label="Type d'audience"
              outlined
              color="primary"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <apply-filters
              :disabled="isLoading"
              outlined
              color="primary"
              title="Appliquer"
            />
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { computed, onMounted, watch } from "@vue/composition-api";

import SiteFilter from "@/components/common/filters/SiteFilter";
import SiteGroupFilter from "@/components/common/filters/SiteGroupFilter";
import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";

import useProxy from "@/hooks/useProxy";
import useQueryString from "@/hooks/useQueryString";

import { URL_PARAM_NAMES } from "@/utils/constants";

export default {
  name: "Filters",
  components: {
    SiteFilter,
    SiteGroupFilter,
    ApplyFilters,
  },
  setup(props, { emit }) {
    const { store } = useProxy();

    const { addQueryStringParam, synchronizeFilterWithQueryString } =
      useQueryString();

    // =>  METHODS

    // =>  COMPUTED
    const isLoading = computed(() => store.getters["common/getLoading"]);
    const audienceType = computed({
      get: () => store.getters["general/getAudienceType"],
      set(val) {
        store.dispatch("general/updateAudienceType", val);
      },
    });

    // =>  WATCH
    watch(audienceType, (newValue) => {
      addQueryStringParam({
        param: URL_PARAM_NAMES["AudienceType"],
        value: newValue,
        // is_multiple: false,
        // is_integer: false,
        // is_boolean: false,
      });
    });

    // =>  HOOKS
    onMounted(async () => {
      await synchronizeFilterWithQueryString({
        dispatcher: "general/updateAudienceType",
        param: URL_PARAM_NAMES["AudienceType"],
        value: audienceType.value,
        // is_multiple: false,
        // is_integer: false,
        // is_boolean: false,
        // dependsOn: undefined,
      });
    });

    // =>  DATA
    return {
      isLoading,
      audienceTypes: [
        { value: "ga", text: "GA" },
        { value: "wysistat", text: "Wysistat" },
      ],
      audienceType,
    };
  },
};
</script>
