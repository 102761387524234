<template>
  <v-card class="mb-10">
    <v-card-title class="text-h5">{{ title }}</v-card-title>
    <v-card-text>
      <vue-apex-charts
        type="line"
        :options="chartOptions"
        :series="series"
        height="450px"
      ></vue-apex-charts>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { formatNumberCompact, formatNumber } from "@/utils/formatting";
export default {
  name: "MonthlyComparison",
  components: {
    VueApexCharts,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    dataLabel: {
      type: String,
      required: true,
    },
  },
  methods: {},
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [5, 3],
          curve: "straight",
          dashArray: [0, 5],
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [
            "Jan",
            "Fev",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yaxis: {
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatNumberCompact(val, 2),
          },
        },
        tooltip: {
          y: {
            formatter: (val) => formatNumber(val),
          },
        },
      },
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    previousYear() {
      return this.currentYear - 1;
    },
    rwmData() {
      return this.$store.getters["general/getRwmData"];
    },
  },
  watch: {
    rwmData: {
      handler() {
        this.series = [
          {
            name: this.currentYear,
            data: this.rwmData[`${this.dataLabel}`].currentYear,
          },
          {
            name: this.previousYear,
            data: this.rwmData[`${this.dataLabel}`].previousYear,
          },
        ];
      },
      deep: true,
    },
  },
};
</script>
